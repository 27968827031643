<template>
  <div>
    <div class="align-center text-center mt-6 mx-6">
      <v-card elevation="6" style="margin: auto" max-width="2000">
        <v-toolbar flat dense class="blue darken-3" dark>
          <v-toolbar-title>{{ $t("tables.eventsList") }}</v-toolbar-title>
        </v-toolbar>
        <div v-if="$i18n.locale == 'en'" class="pl-4 pr-4 pt-2 pb-2">
          <v-data-table
            :headers="headers"
            :search="search"
            :items="entries"
            :items-per-page="15"
            fixed-header
            class="elevation-1"
          >
          </v-data-table>

          <v-row>
            <v-col cols="3">
              <v-select
                background-color="#FAFAFA"
                class="mt-3 ml-4 mr-10"
                :items="periodsToSelect"
                v-model="timePeriod"
                @change="updateTimePeriod(timePeriod)"
                :label="$t('button.chooseTimePeriod')"
                outlined
              ></v-select>
            </v-col>

            <v-col cols="5"></v-col>

            <v-col cols="4" class="mt-2 pt-0" style="">
              <v-card-title>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  :label="$t('tables.search')"
                  single-line
                  hide-details
                  outlined
                ></v-text-field>
              </v-card-title>
            </v-col>
          </v-row>
        </div>

        <div v-if="$i18n.locale == 'pt'" class="pl-4 pr-4 pt-2 pb-2">
          <v-data-table
            :headers="headersPt"
            :search="search"
            :items="entries"
            :items-per-page="15"
            fixed-header
            class="elevation-1"
          >
          </v-data-table>

          <v-row>
            <v-col cols="3">
              <v-select
                background-color="#FAFAFA"
                class="mt-3 ml-4 mr-10"
                :items="periodsToSelectPt"
                v-model="timePeriod"
                @change="updateTimePeriod(timePeriod)"
                :label="$t('button.chooseTimePeriod')"
                outlined
              ></v-select>
            </v-col>

            <v-col cols="5"></v-col>

            <v-col cols="4" class="mt-2 pt-0" style="">
              <v-card-title>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  :label="$t('tables.search')"
                  single-line
                  hide-details
                  outlined
                ></v-text-field>
              </v-card-title>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import AppService from "@/services/AppService";
import HelperMethods from "@/utilities/HelperMethods";
import moment from "moment";
import { mapState } from "vuex";

export default {
  data() {
    return {
      userInfo: {},
      entries: [],
      headers: [
        { text: "Log Id", value: "id" },
        { text: "User Id", value: "userId" },
        { text: "User Role", value: "role" },
        { text: "Description", value: "action" },
        { text: "Date", value: "createdAt" },
      ],
      headersPt: [
        { text: "Id Evento", value: "id" },
        { text: "Id Utilizador", value: "userId" },
        { text: "Função Utilizador", value: "role" },
        { text: "Descrição", value: "action" },
        { text: "Data", value: "createdAt" },
      ],
      search: null,
      periodsToSelect: ["Daily", "Weekly", "Monthly", "All"],
      periodsToSelectPt: ["Diário", "Semanal", "Mensal", "Tudo"],
      timePeriod: "",
      dateFrom: "",
      dateTo: "",
    };
  },
  computed: {
    ...mapState(["userRole"]),
  },
  methods: {
    async getTableEntries() {
      try {
        const response = await AppService.getLogEntriesByPeriodOfTime(
          this.dateFrom,
          this.dateTo
        );
        this.entries = response.data;
      } catch (err) {
        console.log(err);
      }

      this.entries.forEach(function (value) {
        value.createdAt = HelperMethods.formatTimestamp(value["createdAt"]);
        value.role = HelperMethods.nameRole(Number(value["userRole"]));
      });
    },

    updateTimePeriod(timePeriod) {
      this.dateTo = moment().add(1, "days").format("YYYY-MM-DD");
      switch (timePeriod) {
        case "Daily":
          this.dateFrom = moment()
            .subtract(1, "days")
            .format("YYYY-MM-DD h:mm:ss a");
          break;
        case "Weekly":
          this.dateFrom = moment()
            .subtract(1, "weeks")
            .format("YYYY-MM-DD h:mm:ss a");
          break;
        case "Monthly":
          this.dateFrom = moment()
            .subtract(1, "months")
            .format("YYYY-MM-DD h:mm:ss a");
          break;
        case "All":
          this.dateFrom = moment()
            .subtract(5, "years")
            .format("YYYY-MM-DD h:mm:ss a");
          break;
        case "Diário":
          this.dateFrom = moment()
            .subtract(1, "days")
            .format("YYYY-MM-DD h:mm:ss a");
          break;
        case "Semanal":
          this.dateFrom = moment()
            .subtract(1, "weeks")
            .format("YYYY-MM-DD h:mm:ss a");
          break;
        case "Mensal":
          this.dateFrom = moment()
            .subtract(1, "months")
            .format("YYYY-MM-DD h:mm:ss a");
          break;
        case "Tudo":
          this.dateFrom = moment()
            .subtract(5, "years")
            .format("YYYY-MM-DD h:mm:ss a");
          break;
      }
      this.getTableEntries();
    },
  },
  async mounted() {
    this.userInfo = this.$store.getters.userInfo;

    // Setting the default values for time period (daily)
    this.timePeriod = "Weekly"
    this.dateFrom = moment().subtract(1, "weeks").format("YYYY-MM-DD h:mm:ss a");
    this.dateTo = moment().add(0, "days").format("YYYY-MM-DD h:mm:ss a");

    this.getTableEntries();
  },
};
</script>

<style></style>
